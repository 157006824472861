.root {
    width: 26px;
    height: 26px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    flex-shrink: 0;
    position: relative;

    > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
