.root {
    .preview {
        padding: 16px;
        border-radius: 12px;
        background-color: #fafafa;
        font-size: 12px;
        word-break: break-word;

        .header {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            margin: 0 0 6px 0;

            > svg {
                display: block;
                margin: 0 8px 0 0;
            }
        }
    }
}
