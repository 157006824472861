.root {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 8px;
    position: relative;
    z-index: 100000;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    padding: 11px 12px 11px 16px;
    border-radius: 10px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    animation: reveal 0.3s ease forwards;
    pointer-events: all;

    &.closing {
        animation: hide 0.3s ease forwards;
    }
}

.success {
    background-color: #3eb991;
}

.danger {
    background-color: #dc5960;
}

.close {
    display: block;
    color: inherit;
    text-decoration: none;
    margin-left: auto;
    transition: transform 0.3s ease;

    svg {
        display: block;
    }

    &:hover,
    &:focus {
        color: inherit;
    }

    &:hover {
        transform: rotate(90deg);
    }
}

@keyframes reveal {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: none;
        opacity: 1;
    }
}

@keyframes hide {
    0% {
        transform: none;
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}
