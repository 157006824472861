.emoticonPreview {
    --icon-size: 16px;

    text-align: center;
    width: var(--icon-size);
    height: var(--icon-size);
    font-size: var(--icon-size);
    line-height: var(--icon-size);
}

.imagePreview,
div.imagePreview {
    --icon-size: 16px;

    width: var(--icon-size);
    height: var(--icon-size);
}
