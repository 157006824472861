@use "sass:math";

.root {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &.center {
        justify-content: center;
    }

    &.left {
        justify-content: flex-start;
    }

    &.right {
        justify-content: flex-end;
    }
}

.blurred {
    filter: blur(20px);
}

.imageBlock {
    overflow: hidden;
    //box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    cursor: inherit;

    &.clickable {
        cursor: pointer;
    }
}

.imageContainer {
    display: block;
}

.placeholder {
    background-color: #ccc;

    &.original .imageContainer {
        padding-top: math.div(100%, 16) * 9;
    }
}

.original {
    position: relative;
    width: 100%;

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}

.square {
    position: relative;
    width: 100%;

    .imageContainer {
        width: 100%;
        padding-top: 100%;
    }

    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.rectangle {
    position: relative;
    width: 100%;

    .imageContainer {
        width: 100%;
        padding-top: math.div(100%, 16) * 9;
    }

    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.fitInside {
    .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.fitOutside {
    .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.stretch {
    .image {
        background-size: 100% 100%;
    }
}

.fill {
    .image {
        background-position: center;
        background-size: cover;
    }
}
