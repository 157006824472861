.root {
}

.divider {
    width: 100%;
}

.line {
    .divider {
        height: 1px;
        background-color: #ccc;
        border-radius: 10px;

        &.left {
            margin-right: auto;
        }

        &.center {
            margin: 0 auto;
        }

        &.right {
            margin-left: auto;
        }
    }
}

.dots {
    .divider {
        display: flex;
        justify-content: center;

        &.left {
            justify-content: flex-start;

            &.small > div {
                margin: 0 16px 0 0;
            }

            &.medium > div {
                margin: 0 24px 0 0;
            }

            &.large > div {
                margin: 0 36px 0 0;
            }
        }

        &.right {
            justify-content: flex-end;

            &.small > div {
                margin: 0 0 0 16px;
            }

            &.medium > div {
                margin: 0 0 0 24px;
            }

            &.large > div {
                margin: 0 0 0 36px;
            }
        }

        > div {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #ccc;
            margin: 0 8px;

            &:nth-of-type(2n) {
                transform: scale(0.5);
            }
        }

        &.medium > div {
            width: 12px;
            height: 12px;
            margin: 0 12px;
        }

        &.large > div {
            width: 18px;
            height: 18px;
            margin: 0 16px;
        }
    }
}

.space {
    .divider {
        opacity: 0;
    }
}
