@import '@mssgme/scss-basics/vars';
@import '@mssgme/scss-basics/mixins/crop';

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    pointer-events: none;
    transform-origin: 50% 0;
    transition: transform 0.3s ease, top 0.3s ease;

    &.offset {
        top: calc(56px + var(--safe-area-inset-top));
    }
}

.contentWrapper {
    width: 600px;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
}

.root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > * {
        pointer-events: auto;
    }
}

.burger {
    width: var(--burger-width);
    height: var(--burger-height);
    position: absolute;
    top: var(--burger-top);
    right: var(--burger-right);
    border-radius: 50%;
    background-color: #fafafa;
    display: flex;
    cursor: pointer;
    z-index: 1;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04),
                0 0 2px 0 rgba(0, 0, 0, 0.06),
                0 4px 8px 0 rgba(0, 0, 0, 0.04);

    &.darkerShadow {
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2),
        0 0 2px 0 rgba(0, 0, 0, 0.3),
        0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    &.active {
        box-shadow: none;

        .burgerIcon div {
            &:first-child {
                transform: translateY(6px) rotate(135deg);
            }

            &:last-child {
                transform: translateY(-5px) rotate(45deg);
            }
        }
    }
}

.burgerIcon {
    width: 20px;
    height: 20px;
    position: relative;
    margin: auto;

    > div {
        width: 100%;
        height: 3px;
        border-radius: 3px;
        background-color: #fff;
        position: absolute;
        transition: transform 0.3s ease, top 0.3s ease, bottom 0.3s ease;

        &:first-child {
            top: 3px;
        }

        &:last-child {
            top: 14px;
        }
    }
}

.menuContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    //overflow: hidden;
    display: flex;
    transition: visibility 0.3s ease, opacity 0.3s cubic-bezier( 1, 0, 1, 0 );

    .menuBackground {
        position: absolute;
        top: var(--burger-top);
        right: var(--burger-right);
        width: var(--burger-width);
        height: var(--burger-height);
        border-radius: 50%;
        transition: transform 0.3s ease, border-radius 0.1s ease;
    }

    &.active {
        opacity: 1;
        visibility: visible;
        transition: visibility 0.3s ease, opacity 0.3s cubic-bezier( 0, 1, 0, 1 );

        .menuBackground{
            transform: scale(30);
            border-bottom-left-radius: 0;
            transition: transform 0.3s ease, border-radius 0.6s ease;
        }

        .menuList {
            opacity: 1;
            transition: opacity 0.3s ease 0.1s;
        }

        .menuLink {
            transform: none;
            opacity: 1;
        }

        @for $i from 0 through 15 {
            .menuListItem:nth-child(#{$i}) .menuLink {
                transition-delay: #{$i * 0.02}s;
            }
        }
    }
}

.menuList {
    width: 100%;
    max-height: 100%;
    margin: auto;
    padding: 88px 16px 88px 16px;
    list-style: none;
    opacity: 0;
    transition: opacity 0.1s ease;
    overflow: scroll;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        opacity: 0;
    }
}

.menuLink {
    position: relative;
    transform: translate(40px, -40px);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;

    &.active .activeMark {
        width: 40px;
        left: calc(50% - 20px);
        opacity: 1;
    }
}

.activeMark {
    position: absolute;
    bottom: 4px;
    left: 50%;
    width: 0;
    height: 3px;
    border-radius: 3px;
    background-color: #333;
    opacity: 0;
    transition: width 0.1s ease, left 0.1s ease, opacity 0.1s ease;
    pointer-events: none;
}

.menuButton {
    background-color: transparent !important;
    text-decoration: none !important;
    font-size: 34px !important;
    line-height: 40px !important;
    font-weight: 700 !important;
    min-height: initial !important;
    padding: 12px 0 !important;

    &:hover {
        transform: none !important;
        box-shadow: none !important;
    }
}
