@import 'vars';

:root {
    --color-white: #{$background-color};
    --color-light-gray: #{$background-light};
    --bg: var(--color-light-gray);

    --top-banner-padding-top: var(--safe-area-inset-top);
    --top-banner-inner-height: 58px;
    --top-banner-height: calc(var(--top-banner-padding-top) + var(--top-banner-inner-height));
    --m-container-top: var(--safe-area-inset-top);
    --m-container-height: calc(100% - var(--m-container-top));

    --modal-padding-top: 0px;
    --modal-padding-bottom: var(--safe-area-inset-bottom);
    --modal-bg: var(--color-white);
    --modal-max-height: calc(100% - 32px - var(--safe-area-inset-top));
    --modal-width: calc(100% - 32px - var(--safe-area-inset-left) - var(--safe-area-inset-right));
    --modal-header-padding-top: 0px;
    --modal-header-inner-height: 54px;
    --modal-header-height: calc(var(--modal-header-padding-top) + var(--modal-header-inner-height));

    --canvas-border-radius: 0;
    --canvas-header-height: 54px;
    --canvas-dashboard-height: 54px;
    --canvas-dashboard-padding-top: 16px;
    --canvas-dashboard-padding-bottom: 16px;
    --canvas-bg-top: var(--canvas-header-height);
    --canvas-bg-bottom: calc(
        var(--canvas-dashboard-height) +
        var(--canvas-dashboard-padding-top) +
        var(--canvas-dashboard-padding-bottom)
    );

    --burger-top: 16px;
    --burger-right: 16px;
    --burger-width: 56px;
    --burger-height: 56px;

    --preview-burger-top: calc(16px + var(--safe-area-inset-top));
}

@supports (width: max(100vw, 100vh)) {
    :root {
        --canvas-dashboard-padding-bottom: max(calc(var(--safe-area-inset-top) - 10px), 16px);
        --modal-padding-bottom: max(calc(var(--safe-area-inset-bottom) - 10px), 16px);
    }
}

::-moz-selection {
    background: #333;
    color: #fff;
}
::selection {
    background: #333;
    color: #fff;
}

html {
    font-family: $font-family;
    font-size: $font-size;
    line-height: 1.5;
    color: $font-default-color;
    width: 100%;
    height: 100%;
}

body {
    background-color: var(--bg);
    width: 100%;
    min-height: 100%;
    overflow: hidden;

    transition: background-color $default-transition;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
    color: $link-color;
    text-decoration: none;
    font-weight: 500;
}

p {
    font-size: 1em;
    margin: 1em 0;
}

.grecaptcha-badge {
    visibility: hidden;
}

#mssgme_widget {
    //display: none;
    bottom: 60px;
}

@media all and (max-width: $mobile-breakpoint) {
    $circle-offset: 10px;
    $popup-offset: $circle-offset + 71px;
    $popup-height: $popup-offset + 10px;

    #widget_circle {
        bottom: $circle-offset !important;
    }

    #mssg_widget_box {
        bottom: $popup-offset !important;
        max-height: calc(100% - #{$popup-height}) !important;
    }
}

#mssgme_widget.visible {
    //@media all and (max-width: $mobile-breakpoint) {
    display: block !important;
    //}
}
