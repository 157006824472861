.root {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    pointer-events: auto;
}

.ordersButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.12);
    margin: 0 6px 0 0;
    color: #000;

    &.single {
        width: 48px;
        height: 48px;
        margin: 0;
    }

    > svg {
        display: block;
    }
}
