html {
    --safe-area-inset-top: 0px;
    --safe-area-inset-bottom: 0px;
    --safe-area-inset-left: 0px;
    --safe-area-inset-right: 0px;
}

@supports (padding-top: env(safe-area-inset-top)) {
    html {
        --safe-area-inset-top: env(safe-area-inset-top, 0px);
        --safe-area-inset-bottom: env(safe-area-inset-bottom, 0px);
        --safe-area-inset-left: env(safe-area-inset-left, 0px);
        --safe-area-inset-right: env(safe-area-inset-right, 0px);
    }
}
