.placeholder {
    width: 100%;
    height: 68px;
}

.root {
    --root-position: fixed;
    --logo-animation: 5s wobbleWithDelay 2s ease-in-out infinite;
    --icon-arrow-opacity: 0;
    --icon-arrow-transform: none;
    --icon-arrow-transform-hover: translateX(3px);
    --icon-close-transform: none;
    --icon-close-transform-hover: rotate(-90deg);

    display: flex;
    justify-content: center;
    margin-top: auto;
    padding-top: 8px;
    margin-bottom: 16px;
    font-family: 'Inter', sans-serif;
    position: var(--root-position);
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
}

.userUnpinned {
    --root-position: static;
    --logo-animation: none;
    --icon-arrow-opacity: 1;
}

.gradient {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    pointer-events: none;
    background: linear-gradient(0deg, rgba(0,0,0,0.6), rgba(0,0,0,0));
    z-index: 0;
}

.linkWrapper {
    position: relative;
}

.closeIconButton {
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        --icon-close-transform: var(--icon-close-transform-hover);
    }
}

.closeIcon {
    display: block;
    width: 18px;
    height: auto;
    transition: transform 0.3s ease;
    transform: var(--icon-close-transform);
}

.brandingLink {
    padding: 10px 16px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.7;
    letter-spacing: 0.15px;
    color: #fff;
    transition: color 0.3s ease, background 0.3s ease, border 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        --icon-arrow-transform: var(--icon-arrow-transform-hover);
        --logo-animation: wobble 1s ease-in-out;
    }
}

.logo {
    display: block;
    width: 20px;
    height: auto;
    margin: 0 8px 0 0;
    animation: var(--logo-animation);
}

.arrowIcon {
    display: block;
    width: 16px;
    height: auto;
    margin: 0 0 0 4px;
    transition: transform 0.3s ease;
    transform: var(--icon-arrow-transform);
    opacity: var(--icon-arrow-opacity);
}

@keyframes wobble {
    0% {
        transform: translate3d(0, 0, 0);
    }

    15% {
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }

    30% {
        transform: translate3d(12%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    45% {
        transform: translate3d(-9%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    60% {
        transform: translate3d(6%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }

    75% {
        transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes wobbleWithDelay {
    0% {
        transform: translate3d(0, 0, 0);
    }

    3% {
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }

    6% {
        transform: translate3d(12%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    9% {
        transform: translate3d(-9%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    12% {
        transform: translate3d(6%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }

    15% {
        transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    20% {
        transform: translate3d(0, 0, 0);
    }
}
