.root {
}

.list {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: item;

    > li {
        position: relative;
        padding: 0 0 0 20px;
        margin: 0 0 4px 0;
        counter-increment: item;

        &::before {
            content: '•';
            display: flex;
            width: 20px;
            height: 24px;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
        }

        &:last-of-type {
            margin: 0;
        }
    }

    &.line > li::before {
        content: '-';
    }

    &.number > li::before {
        content: counter(item) '.';
    }
}
