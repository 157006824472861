.root {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 30px);
    grid-gap: 0;
}

.element {
    align-self: stretch;
    justify-self: stretch;
    overflow: hidden;
}
