.root {
    line-height: 1.6;
    white-space: pre-wrap;
    word-break: break-word;

    > *:last-child {
        margin-bottom: 0;
    }
}

.ltr {
    direction: ltr;
}

.rtl {
    direction: rtl;
}

.placeholder {

}

.image {
    display: inline-block;
    margin: 0;
    max-width: 100%;
    height: auto;
}

.code {

}

.textBold {
    font-weight: bold;
}

.textItalic {
    font-style: italic;
}

.textUnderline {
    text-decoration: underline;
}

.textStrikethrough {
    text-decoration: line-through;
}

.textUnderlineStrikethrough {
    text-decoration: underline line-through;
}

.textOverflowed {

}

.textCode {

}

.hashtag {

}

.link {
    color: rgb(33, 111, 219);
    text-decoration: none;
}

.paragraph {
    margin: 0;
    position: relative;
}

.headingH1,
.headingH2,
.headingH3,
.headingH6 {
    font-weight: 400;
    padding: 0;
    margin: 0 0 10px 0;

    &:not(:first-child) {
        margin-top: 24px;
    }
}

.headingH1 {
    font-size: 2.125em;
    line-height: 1.2;
    margin: 0 0 8px 0;
}

.headingH2 {
    font-size: 1.5em;
    line-height: 1.3;
    margin: 0 0 9px 0;
}

.headingH3,
.headingH4,
.headingH5,
{
    font-size: 1.25em;
    line-height: 1.35;
    margin: 0 0 10px 0;
}

.headingH4 {
    font-size: 1.125em;
    line-height: 1.475;
}

.headingH5 {
    font-size: 1em;
    line-height: 1.6;
}

.headingH6 {
    font-size: 0.75em;
    margin: -2px 0 10px 0;
    line-height: 1.5;

    &:not(:first-child) {
        margin-top: 0;
    }
}

.quote {
    margin: 0 0 10px 12px;
    font-size: 15px;
    padding-left: 20px;
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 4px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #333;
        border-radius: 2px;
    }
}

.listOl,
.listUl {
    padding: 0;
    margin: 0 0 10px 28px;
}

.listItem {
    margin: 0 0 4px 0;

    &:last-of-type {
        margin: 0;
    }
}

.nestedListItem {
    list-style-type: none;
}

pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
}

pre::-webkit-scrollbar-thumb {
    background: #999;
}
