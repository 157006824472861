.root {
    display: flex;
    justify-content: center;
    margin-top: auto;
    padding-top: 8px;
    margin-bottom: 16px;
}

$backgroundColor: #f7f7fa;
$activeColor: #ff3265;
$color: #000;

.brandingLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: normal;
    white-space: nowrap;

    color: $color;
    background-color: $backgroundColor;

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;

        > svg {
            display: block;
            width: 20px;
            height: 20px;
            margin-right: -2px;
        }
    }

    .description {
        margin-top: 8px;
        line-height: 24px;
        display: flex;
        flex-direction: row;

        span {
            font-weight: bold;
        }
    }

    > a {
        color: $activeColor;
        font-size: 12px;
        font-weight: bold;
        border: 2px solid $activeColor;
        border-radius: 100px;
        padding: 4px 8px;
        margin: 8px 0 12px 0;
        cursor: pointer;
    }
}
