.root {
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 1;

    width: 48px;
    height: 48px;

    display: flex;

    background-color: #fff;
    border-radius: 100%;
    box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.12);

    cursor: pointer;

    &.steady {
        position: relative;
        right: 0;
        bottom: 0;
    }

    &.absolute {
        position: absolute;
    }

    .icon {
        margin: auto;
        color: #000;
    }

    .badge {
        position: absolute;
        top: 0;
        right: 0;
        background-color: red;
        color: #fff;
        font-size: 9px;
        height: 16px;
        min-width: 16px;
        border-radius: 16px;
        border: 1px solid #fff;
        padding: 0 4px;
        line-height: 14px;
        text-align: center;
        vertical-align: middle;
    }
}
