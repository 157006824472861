.root {
    .content {
        width: 100%;
        margin: 0;
        line-height: 1.5;
        font-style: italic;
        white-space: pre-wrap;
        word-break: break-word;
        position: relative;

        &.line {
            padding: 0 0 0 0.875em;
            border-left-width: 2px;
            border-left-style: solid;
        }

        &.quote {
            padding: 0 0 0 1em;

            &::before {
                content: '“';
                display: block;
                position: absolute;
                top: 0;
                left: -0.15em;
                font-size: 2em;
                line-height: 1;
            }
        }
    }

    .author {
        width: 100%;
        text-align: right;
        font-size: 90%;
        font-weight: 500;
        margin: 8px 0 0 0;
    }
}
