.root {
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &.left {
        > li {
            margin: 2px 16px 2px 0;
        }
    }

    &.right {
        > li {
            margin: 2px 0 2px 16px;
        }
    }

    > li {
        margin: 2px 8px;

        > a {
            display: flex;
        }
    }
}
