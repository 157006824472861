.root {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
}
