.spinnerContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &.inline {
        position: unset;
        width: 30px;
        margin: auto;
    }
}

.loader {
    width: 30px;
    height: 6px;
    position: relative;
}

.dot {
    width: 6px;
    height: 6px;
    background: #007aff;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 3px);

    $easing: cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;

    &.dot1 {
        left: 0;
        animation: dot-jump 0.25s $easing;
    }

    &.dot2 {
        left: 12px;
        animation: dot-jump 0.25s 0.1s $easing;
    }

    &.dot3 {
        left: 24px;
        animation: dot-jump 0.25s 0.2s $easing;
    }
}

@keyframes dot-jump {
    0% {
        transform: translateY(2px);
    }
    100% {
        transform: translateY(-4px);
    }
}
