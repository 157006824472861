$backgroundColor: #fff;
$activeColor: #333;
$color: #000;

.root {
    height: 33px;
    //margin-top: 8px;
    //margin-bottom: 16px;
}

.brandingLink {
    position: fixed;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    bottom: 0;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    //border-radius: 10px;
    font-size: 14px;
    font-weight: normal;
    white-space: nowrap;
    flex: 1;

    color: $color;
    background-color: $backgroundColor;

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;

        > svg {
            display: block;
            width: 16px;
            height: 16px;
            margin-right: -2px;
        }
    }

    .description {
        margin: 4px 0;
        line-height: 24px;
        display: flex;
        flex-direction: row;

        span {
            //font-weight: bold;
        }
    }

    a {
        color: $activeColor;
    }
}
