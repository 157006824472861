.panelWrapper {
    display: block;
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;

    &.center.withCover {
        margin-top: -50px;
    }

    &.left,
    &.right {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 12px;

        &.withCover {
            margin-top: -20px;
        }

        .logoBox {
            width: 90px;
            height: 90px;
        }

        .content {
            text-align: left;
            overflow: hidden;

            > h1 {
                width: 100%;
                font-size: 24px;
                margin: 31px 0 0 0;
            }

            > p {
                opacity: 0.8;
            }
        }
    }

    &.right {
        grid-template-columns: 1fr auto;

        .logoBox {
            order: 2;
        }

        .content {
            text-align: right;
        }
    }
}

.logoBox {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid #fff;
    margin: 0 auto 0 auto;
    position: relative;

    > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.content {
    width: 100%;
    text-align: center;
    word-wrap: break-word;

    > h1 {
        font-weight: bold;
        font-size: 26px;
        line-height: 1.1;
        margin: 6px 0 0 0;
    }

    > p {
        margin: 0;
        font-size: 14px;
    }
}
