.root {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    top: 0;
    left: 0;
    padding: 16px;
    width: 100%;
    z-index: 100000;
    pointer-events: none;
    padding-top: calc(var(--safe-area-inset-top) + 16px);
}
