@use "sass:math";

.root {
    h1,
    h2,
    h3,
    h4 {
        margin: 0;
        word-break: break-word;
        white-space: pre-wrap;
    }

    h1 {
        font-size: math.div(34px, 16px) * 100%;
        font-weight: bolder;
    }

    h2 {
        font-size: math.div(24px, 16px) * 100%;
        font-weight: bold;
    }

    h3 {
        font-size: math.div(20px, 16px) * 100%;
        font-weight: 600;
    }

    h4 {
        font-size: math.div(18px, 16px) * 100%;
        font-weight: 500;
    }
}
