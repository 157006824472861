.root {
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 0.02px;

    display: flex;

    > li {
        flex: 1;
        width: 100%;

        &.break {
            display: none;
            flex: 1 0 100%;
        }
    }
}

.placeholder {
    display: flex;
    flex-wrap: wrap;

    > div {
        flex: 1;
        height: 56px;
        background-color: #f2f2f2;
        border-radius: 10px;
    }
}

.compactMode {
    $gap: 4px;

    .list {
        flex-direction: row;

        > li {
            margin: 0 $gap 0 0;

            &:last-of-type {
                margin: 0;
            }

            &.break {
                margin-top: $gap;
            }
        }
    }

    .placeholder {
        flex-direction: row;

        > div {
            margin: 0 $gap 0 0;
            border-radius: 0;

            &:first-of-type {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }

            &:last-of-type {
                margin: 0;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }

            &:nth-of-type(2),
            &:nth-of-type(3) {
                border-radius: 0 !important;
            }
        }
    }
}

.beefyMode {
    $gap: 10px;

    .list {
        flex-direction: row;

        > li {
            margin: 0 $gap 0 0;

            &:last-of-type {
                margin: 0;
            }

            &.break {
                margin-top: $gap;
            }
        }
    }

    .placeholder > div {
        margin: 0 $gap 0 0;
    }
}

.listMode {
    $gap: 8px;

    .list {
        flex-direction: column;

        > li {
            width: 100%;
            margin: 0 0 $gap 0;

            &:last-of-type {
                margin: 0;
            }

            &.break {
                margin-top: $gap;
            }
        }
    }

    .placeholder {
        display: block;

        > div {
            display: none;
            flex-shrink: 0;
            width: 100%;
            margin: 0 0 $gap 0;

            &:nth-of-type(1),
            &:nth-of-type(2) {
                display: block;
            }
        }
    }
}

.squeeze {
    .list {
        flex-wrap: wrap;
    }

    ul[data-count="5"] {
        > li:nth-of-type(5) {
            margin: 0;
        }

        > .break:nth-of-type(6) {
            display: block;
        }
    }
}
