.content {
    padding: 16px;
    text-align: center;
}

.logo {
    display: block;
    width: 72px;
    height: auto;
    margin: 0 auto;
    fill: currentColor;
}

.heading {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 6px 0;
}

.text {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 16px 0;
    opacity: 0.6;
}
