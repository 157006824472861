.root {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    .item {
        display: flex;
        flex-direction: row;
        background-color: #eee;
        border-radius: 10px;
        padding: 16px 16px 16px 56px;
        position: relative;

        .icon {
            display: flex;
            width: 24px;
            height: 24px;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 16px;
            left: 16px;
        }

        .wrapper {
            display: flex;
            flex-direction: column;

            .title {
                font-size: 16px;
                font-weight: bold;
                line-height: 24px;
                word-break: break-word;
                margin-bottom: 5px;
                vertical-align: center;
            }

            .content {
                white-space: pre-wrap;
                word-break: break-word;
            }
        }
    }

    .item + .item {
        margin-top: 8px;
    }
}
