.root {
    padding: 0 16px;
    overflow: hidden;
    width: 100%;

    &.left, &.right {
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 16px;

        .content {
            text-align: left;
            align-self: center;

            > h1 {
                margin-top: 0;
            }
        }
    }

    &.right {
        grid-template-columns: 1fr auto;

        .logoBox {
            order: 2;
        }

        .content {
            text-align: right;
        }
    }
}

.logoBox {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    position: relative;

    > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .logoBorder {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        border: 4px solid #fff;
        pointer-events: none;
    }
}

.content {
    width: 100%;
    text-align: center;
    overflow: hidden;
    word-wrap: break-word;

    > h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 1.2;
        margin: 16px 0 0 0;
    }

    > p {
        margin: 4px 0 0 0;
        font-weight: 400;
        font-size: 16px;
    }
}
