.root {
    > p {
        margin: 0;
        white-space: pre-wrap;
        word-break: break-word;
    }
}

.inList {
    padding: 0 16px;
    margin: 16px 0;
}
