.root {
    display: flex;
    justify-content: center;
    margin-top: auto;
    padding-top: 8px;
    margin-bottom: 16px;
    font-family: 'Inter', sans-serif;
}

.brandingLink {
    padding: 10px 16px;
    border-radius: 100px;
    border: 1px solid rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.7;
    letter-spacing: 0.15px;
    color: #fff;

    &:hover {
        --logo-animation: wobble 1s ease-in-out;
        --icon-transform: translateX(3px);
    }

    .logo {
        display: block;
        width: 20px;
        height: auto;
        margin: 0 8px 0 0;
        animation: var(--logo-animation, none);
    }

    .arrowIcon {
        display: block;
        width: 16px;
        height: auto;
        margin: 0 0 0 4px;
        transition: transform 0.3s ease;
        transform: var(--icon-transform, none);
    }
}

@keyframes wobble {
    0% {
        transform: translate3d(0, 0, 0);
    }

    15% {
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }

    30% {
        transform: translate3d(12%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    45% {
        transform: translate3d(-9%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    60% {
        transform: translate3d(6%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }

    75% {
        transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}
