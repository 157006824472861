.root {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    .item {
        display: flex;
        flex-direction: column;

        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;

            font-weight: bold;
            line-height: 19px;

            .titleIcon {
                margin: 0 0 0 8px;

                > line:first-child {
                    transition: transform 0.6s ease;
                    transform-origin: 50%;
                }

                &.opened > line:first-child {
                    transform: scaleY(0);
                }
            }

            .titleText {
                flex: 1;
                word-break: break-word;
            }
        }

        .content {
            white-space: pre-wrap;
            word-break: break-word;
            padding: 0 28px 0 0;
        }
    }

    .item + .item {
        margin-top: 17px;
    }
}
