.logoBox {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #fff;
    margin: -50px auto 0 auto;
    position: relative;

    > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.content {
    text-align: center;
    word-wrap: break-word;

    > h1 {
        font-weight: bold;
        font-size: 26px;
        line-height: 1.1;
        margin: 8px 0 0 0;
    }

    > p {
        opacity: 0.8;
        margin: 0;
        font-size: 14px;
    }
}
