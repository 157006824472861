.root {
    display: flex;
    width: 100%;
    height: 56px;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #2f3542;
    user-select: none;
    overflow: hidden;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    font-weight: 500;
    font-size: 16px;
    line-height: 56px;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 0.02px;
    cursor: pointer;

    @media all and (min-width: 1025px) {
        &:hover {
            box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.24);
            transform: translateY(-2px);
        }
    }

    > img,
    > svg {
        fill: currentColor;
        display: inline-block;
        width: 21px;
        height: auto;
        vertical-align: top;
        margin: 0;
        pointer-events: none;
        position: relative;
    }

    > span {
        display: inline-block;
        vertical-align: top;
        margin: 0 0 0 8px;
        pointer-events: none;
        position: relative;
    }
}

.messenger {
    background-image: linear-gradient(to bottom, #00b1ff, #006dfe);

    > span::before {
        content: 'Messenger';
    }
}

.whatsapp {
    background-image: linear-gradient(to bottom, #60fd7c, #07b825);

    > span::before {
        content: 'WhatsApp';
    }
}

.telegram {
    background-image: linear-gradient(to bottom, #00aefa, #0983c5);

    > span::before {
        content: 'Telegram';
    }
}

.vk {
    background-image: linear-gradient(to bottom, #66a2e7, #3874b9);

    > span::before {
        content: 'Vkontakte';
    }
}

.skype {
    background-image: linear-gradient(to bottom, #00bbf2, #007ad4);

    > span::before {
        content: 'Skype';
    }
}

.viber {
    background-image: linear-gradient(to bottom, #8760fd, #4c389d);

    > span::before {
        content: 'Viber';
    }
}

.instagram {
    position: relative;
    background-image: linear-gradient(45deg, #FEC21D 15.1%, #F0000E 50.52%, #BF00A3 84.9%);

    //&::before {
    //    content: '';
    //    display: block;
    //    width: 100%;
    //    height: 100%;
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.2));
    //}

    > span::before {
        content: 'Direct';
    }
}

.compact {
    &.notFirst {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }

    &.notLast {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
}

.beefy {
    flex-direction: column;
    height: 56px;
    padding: 8px;

    span {
        font-size: 11px;
        font-weight: normal;
        line-height: 11px;
        margin: 3px 0 0 0;
    }

    svg {
        margin-bottom: 3px;
    }
}

.list {
    &.root {
        justify-content: flex-start;

        img,
        svg {
            margin: 0 24px;
        }

        span {
        }

        .splitter {
            height: 100%;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            margin-right: 18px;
            position: relative;
        }
    }
}

.themeColors {
    background-image: none;
}
