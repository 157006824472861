.root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    user-select: none;

    .timerPeriod {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 8px;
        min-width: 60px;

        &:first-of-type {
            margin: 0 8px 0 0;
        }

        &:last-of-type {
            margin: 0 0 0 8px;
        }

        .counter {
            font-weight: bold;
            font-size: 44px;
            line-height: 1;
        }

        .label {
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            margin: 4px 0 0 0;
        }
    }
}
